import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import ReactFlow, { Background, MiniMap } from 'reactflow';
import ConnectionLine from './ConnectionLine';
import ConnectionLineConnect from './ConnectionLineConnect';
import InputNode from 'components/Nodes/InputNode';
import OutputNode from 'components/Nodes/OutputNode';
import DefaultNode from 'components/Nodes/DefaultNode';
import FilterNode from 'components/Nodes/FilterNode';
import JoinNode from 'components/Nodes/JoinNode';
import UnionNode from 'components/Nodes/UnionNode';
import ResizeRotateNode from 'components/Nodes/ResizeRotateNode';
import TextInputNode from 'components/Nodes/TextInputNode';
import ModellingKPICard from 'components/Cards/ModellingKPICard';
import { numberToString } from 'utils/unitConversion';
import ZoomBtn from 'components/Buttons/ZoomBtn';
import { addThousandSeparators } from 'utils/unitConversion';
import { MinusCircleIcon, PlusCircleIcon } from '@heroicons/react/solid';
import RadixTooltip from 'components/Tooltips/RadixTooltip';
import ContextMenu from './ContextMenu';

const nodeTypes = {
  defaultNode: DefaultNode,
  inputNode: InputNode,
  outputNode: OutputNode,
  filterNode: FilterNode,
  joinNode: JoinNode,
  unionNode: UnionNode,
  commentNode: ResizeRotateNode,
  textInputNode: TextInputNode,
};

const edgeTypes = {
  default: ConnectionLine,
};

const Canvas = ({
  reactFlowWrapper,
  reactFlowInstance,
  setReactFlowInstance,
  nodes,
  edges,
  onConnect,
  onNodesChange,
  onEdgesChange,
  onEdgesDelete,
  takeSnapshot,
  onDrop,
  setActiveNode,
  metrics,
  snapToGrid,
  onNodeContextMenu,
  onPaneContextMenu,
  menu,
  setMenu,
  onMove,
  isModellingUser
}) => {
  const proOptions = {
    // passing in the account property will enable hiding the attribution
    account: 'paid-pro',
    // in combination with the account property, hideAttribution: true will remove the attribution
    hideAttribution: true,
  };

  const [toggle, setToggle] = useState(false)
  const [takeSnapshotBool, setTakeSnapshotBool] = useState(true)

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onNodeClick = (event, node) => {
    if (node.type != 'commentNode' && node.type != 'textInputNode') {
      setActiveNode({ node, event });
    }
  };


  const zoomIn = () => {
    reactFlowInstance.zoomIn();
  };
  const zoomOut = () => {
    reactFlowInstance.zoomOut();
  };

  const nodeColor = (node) => {
    switch (node.label) {
      case 'Input':
      case 'Output':
        return 'rgb(39,39,42)';
      case 'Combine':
      case 'Stack':
        return 'rgb(255, 84, 120, 0.5)';
      case 'Date Format':
      case 'Difference':
      case 'Date Difference':
      case 'Calculate':
      case 'IF Column':
      case 'Text Column':
      case 'Parse JSON':
        return 'rgb(255,198,186, 0.75)';
      case 'Transpose':
      case 'Pivot':
      case 'Group By':
        return 'rgb(93, 166, 252, 0.5)';
      default:
        return 'rgb(63, 63, 70, 0.5)';
    }
  };

  const onPaneClick = useCallback(
    () => {
      // 👇 make adding nodes undoable
      setActiveNode({});
      setMenu(null)
    },
    [takeSnapshot]
  );

  const onNodeDrag = useCallback(() => {
    // 👇 make dragging a node undoable
    if (takeSnapshotBool) {
      takeSnapshot();
      setTakeSnapshotBool(false)
    }

    // 👉 you can place your event handlers here
  }, [takeSnapshot, takeSnapshotBool]);

  const onNodeDragStop = () => {
    setTakeSnapshotBool(true)
  }

  const onSelectionDragStart = useCallback(() => {
    // 👇 make dragging a selection undoable
    takeSnapshot();
  }, [takeSnapshot]);

  const onNodesDelete = useCallback(() => {
    // 👇 make deleting nodes undoable
    takeSnapshot();
  }, [takeSnapshot]);


  return (
    <div
      className="flex w-screen h-screen outline-0  relative"
      id="reactFlowWrapper"
      ref={reactFlowWrapper}
      tabIndex="2"
    >
      <div className="flex flex-row gap-2 absolute z-[1]  top-0  mt-[115px] right-[2.5vw] my-3">
        <ModellingKPICard
          withtooltip={true}
          tooltipText={
            typeof metrics.rows !== 'undefined'
              ? addThousandSeparators(metrics.rows)
              : 'Not a number'
          }
          margin="110%"
          measure={'Rows'}
          metric={addThousandSeparators(metrics.rows)}
        />
        <ModellingKPICard measure={'Columns'} metric={numberToString(metrics.columns)} />
        <ModellingKPICard measure={'Actions'} metric={metrics.tools} />
        <div className="flex flex-row gap-2 absolute top-11 right-0 py-3 ">
          <RadixTooltip tooltipText="Zoom in">
            <ZoomBtn icon={<PlusCircleIcon className="h-4 w-4" />} onClick={zoomIn} />
          </RadixTooltip>
          <RadixTooltip tooltipText="Zoom out">
            <ZoomBtn icon={<MinusCircleIcon className="h-4 w-4" />} onClick={zoomOut} />
          </RadixTooltip>
          <RadixTooltip tooltipText={`${toggle === true ? 'Hide minimap' : 'Show minimap'}`}>
            <ZoomBtn
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={2}
                  stroke="currentColor"
                  className="w-4 h-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 6.75V15m6-6v8.25m.503 3.498l4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 00-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0z"
                  />
                </svg>
              }
              onClick={() => setToggle(!toggle)}
            />
          </RadixTooltip>
        </div>
      </div>
      <ReactFlow
        proOptions={proOptions}
        nodes={nodes}
        edges={edges}
        nodeTypes={nodeTypes}
        onNodeDrag={onNodeDrag}
        onNodeDragStop={onNodeDragStop}
        onSelectionDragStart={onSelectionDragStart}
        onNodeContextMenu={onNodeContextMenu}
        onPaneContextMenu={onPaneContextMenu}
        onSelectionContextMenu={onPaneContextMenu}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onEdgesDelete={onEdgesDelete}
        onNodeClick={onNodeClick}
        onNodesDelete={onNodesDelete}
        onConnect={onConnect}
        onDragOver={onDragOver}
        onPaneClick={onPaneClick}
        onDrop={onDrop}
        onMove={onMove}
        onInit={setReactFlowInstance}
        edgeTypes={edgeTypes}
        connectionLineComponent={ConnectionLineConnect}
        grid={20}
        snapToGrid={snapToGrid}
        deleteKeyCode={isModellingUser ? ['Backspace', 'Delete'] : []}
        panOnScroll
        zoomActivationKeyCode={['Meta', 'Control']}
        edgesUpdatable={isModellingUser}
        edgesFocusable={isModellingUser}
        nodesDraggable={isModellingUser}
        nodesConnectable={isModellingUser}
        nodesFocusable={isModellingUser}
        elementsSelectable={isModellingUser}
      >
        <Background className="dark:bg-zinc-950" color="#71717a" gap={16} size={1} />
        {menu && <ContextMenu onClick={() => setMenu(null)} {...menu} />}
        {toggle && (
          <MiniMap
            className="ml-[2.5vw] mt-[115px] overflow-hidden bg-zinc-50 dark:bg-zinc-800 rounded-xl border h-36 w-auto border-zinc-200 dark:border-zinc-700 shadow"
            maskColor="rgb(212, 212, 216, 0.5)"
            nodeStrokeWidth={4}
            nodeStrokeColor="#D4D4D8"
            nodeBorderRadius={10}
            nodeColor={nodeColor}
            pannable
            position="top-left"
          />
        )}
      </ReactFlow>
    </div>
  );
};


Canvas.propTypes = {
  reactFlowWrapper: PropTypes.object,
  reactFlowInstance: PropTypes.object,
  setReactFlowInstance: PropTypes.func,
  nodes: PropTypes.array,
  edges: PropTypes.array,
  onConnect: PropTypes.func,
  onNodesChange: PropTypes.func,
  onEdgesChange: PropTypes.func,
  onEdgesDelete: PropTypes.func,
  takeSnapshot: PropTypes.func,
  onDrop: PropTypes.func,
  setActiveNode: PropTypes.func,
  metrics: PropTypes.object,
  undo: PropTypes.func,
  redo: PropTypes.func,
  snapToGrid: PropTypes.any,
  onNodeContextMenu: PropTypes.any,
  onPaneContextMenu: PropTypes.any,
  menu: PropTypes.any,
  setMenu: PropTypes.any,
  onMove: PropTypes.func,
  isModellingUser: PropTypes.bool,
};

export default React.memo(Canvas);