import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// input components
import TextDescInput from './TextDesc';
import PasswordInput from './PasswordInput';
import TextInputModelling from 'components/Modelling/Config/TextInputModelling';
//import SingleSelect from 'components/Modelling/Config/SingleSelect';
import Select from 'components/Modelling/Config/Select';
import GridRadioGroup from 'components/Modelling/Config/GridRadioGroup';
import GridTextInput from 'components/Modelling/Config/GridTextInput';
// import Formulabox from './Formulabox';
import Description from 'components/Modelling/Config/Descriptions';
//import MultiSelect from 'components/Modelling/Config/MultiSelect';
import MSelect from 'components/Modelling/Config/MSelect';

import TwoRowGroup from 'components/Modelling/Config/TwoRowGroup';
import Conditional from 'components/Modelling/Config/Conditional';
import ModellingFilter from 'components/Modelling/Config/ModellingFilter';
import TableClick from 'components/Modelling/Config/TableClick';
import TableSelect from 'components/Modelling/Config/TableSelect/TableSelect';
import KeyValueField from 'components/Modelling/Config/KeyValueField';
import ComboboxInput from 'components/Modelling/Config/ComboboxInput';
import CheckboxText from 'components/Modelling/Config/CheckboxText';
import RadioText from 'components/Modelling/Config/RadioText';
import TableSelect2 from 'components/Modelling/Config/TableSelect/TableSelectColumns';

//import MultiSelect from 'components/Modelling/Config/MultiSelect';

import ModelconfigAccordion from 'components/Accordions/ModelconfigAccordion';
import RadioGroupModelling from 'components/Modelling/Config/RadioGroupModelling';
import TextAreaModelling from 'components/Modelling/Config/TextAreaModelling';
import Checkbox from 'components/Modelling/Config/Checkbox';

const FormBuilderModelling = ({
  inputs,
  initState,
  //submitHandler,
  stateChangeCallback,
  activeNode,
  databases,
  tables,
  // mode,
}) => {
  const [state, setState] = useState(initState);
  const [activeTables, setActiveTables] = useState(tables);

  // console.log("init", initState)
  // console.log("active", activeNode)

  useEffect(() => {
    function componentDidMount() {
      stateChangeCallback(state);
    }
    componentDidMount();
  }, [state]);

  const inputOptions = (ipt) => {
    switch (ipt.optionType) {
      case 'sources':
        return [
          ...new Map(
            tables.map((table) => [
              table.source_name || table.source_type,
              {
                label: table.source_name || table.source_type,
                value: table.source_name.toString(),
              },
            ])
          ).values(),
        ];
      case 'tables':
        return activeTables.map((table) => ({
          label: table?.table_name,
          value: table?.table_name,
          type: table?.source_type,
          source: table?.source_name,
          source_id: table?.source_id,
          db_id: table?.db_id,
          //Legacy fix
          tableId: table?.table_name,
          dbId: table?.db_id,
        }));
      case 'databases':
        return databases.map((database) => ({
          label: database?.name,
          value: database?.id,
        }));
      case 'columns':
        return Object.keys(activeNode.data?.[`${ipt.options}_metadata`] || {}).map((column) => ({
          label: column,
          value: column,
          type: activeNode.data?.dataframe_metadata?.[column],
        }));
      case 'multiColumns':
        var combinedOptions = [];
        ipt.options.map((option) => {
          combinedOptions = [
            ...combinedOptions,
            ...Object.keys(activeNode.data?.[`${option}_metadata`] || {}).map((column) => ({
              label: option + '_' + column,
              value: option + '_' + column,
              type: activeNode.data?.dataframe_metadata?.[column],
            })),
          ];
        });
        // console.log('combinedOptions', combinedOptions);
        return combinedOptions;
      case 'manual':
        return ipt.options;
      default:
        return [];
    }
  };

  const rightInputOptions = (ipt) => {
    switch (ipt.rightOptionType) {
      case 'sources':
        return [
          ...new Map(
            tables.map((table) => [
              table.source_name || table.source_type,
              {
                label: table.source_name || table.source_type,
                value: table.source_name.toString(),
              },
            ])
          ).values(),
        ];
      case 'tables':
        return activeTables.map((table) => ({
          label: table?.table_name,
          value: table?.table_name,
          type: table?.source_type,
          source: table?.source_name,
          source_id: table?.source_id,
          db_id: table?.db_id,
          //Legacy fix
          tableId: table?.table_name,
          dbId: table?.db_id,
        }));
      case 'databases':
        return databases.map((database) => ({
          label: database?.name,
          value: database?.id,
        }));
      case 'columns':
        return Object.keys(activeNode.data?.[`${ipt.rightOptions}_metadata`] || {}).map(
          (column) => ({
            label: column,
            value: column,
            type: activeNode.data?.dataframe_metadata?.[column],
          })
        );
      case 'multiColumns':
        var combinedOptions = [];
        ipt.options.map((option) => {
          combinedOptions = [
            ...combinedOptions,
            ...Object.keys(activeNode.data?.[`${option}_metadata`] || {}).map((column) => ({
              label: option + '_' + column,
              value: option + '_' + column,
              type: activeNode.data?.dataframe_metadata?.[column],
            })),
          ];
        });
        // console.log('combinedOptions', combinedOptions);
        return combinedOptions;
      case 'manual':
        return ipt.rightOptions;
      default:
        return [];
    }
  };

  const handleChangeFunction = (ipt) => {
    switch (ipt.optionType) {
      case 'sources':
        return (key, value) => {
          const newState = Object.assign({}, state);
          newState[key] = value;
          const activeSource = tables.filter((table) => table?.source_name === value);
          setActiveTables(activeSource);
          setState({ ...newState });
          stateChangeCallback(newState);
        };
      case 'tables':
        return (key, value) => {
          const newState = Object.assign({}, state);
          setState({ ...newState, ...value });
          stateChangeCallback({ ...newState, ...value });
        };
      default:
        return (key, value) => {
          const newState = Object.assign({}, state);
          newState[key] = value;
          setState({ ...newState });
          stateChangeCallback({ ...newState });
        };
    }
  };

  const generateFields = (inputs) => {
    const fields = inputs.map((ipt) => {
      const {
        fieldType,
        fieldName,
        fieldText,
        description,
        placeholder,
        withtooltip,
        leftHeader,
        rightHeader,
        tooltip,
        ttmargin,
        btnOn,
        secondHeader,
        columnHeaders,
        optional,
        leftKeyName,
        rightKeyName,
        columnCombo,
        conditions,
      } = ipt;

      const options = inputOptions(ipt);
      const rightOptions = rightInputOptions(ipt);
      let render = true;
      const handleChange = handleChangeFunction(ipt);
      let defaultValue = ipt.defaultValue;
      if (ipt?.prefillValues === 'datatypes') {
        const preFillValues = {};
        options.map((option) => {
          preFillValues[option.value] = option.type;
        });
        defaultValue = { ...preFillValues, ...state[fieldName] };
      }

      if (conditions) {
        for (const condition of conditions) {
          let conditionFieldName = state[condition['conditionFieldName']];
          render = eval(condition['condition']);
          console.log(condition['conditionFieldName'], render, conditionFieldName)
        }
      }

      if (render == false) {
        return null;
      }

      switch (fieldType) {
        case 'dropdown':
          // if (optional && options.length == 0) {
          // //   return null;
          // // }
          return (
            <div className="py-4">
              <Select
                key={fieldName}
                header={fieldText}
                items={options}
                withtooltip={withtooltip}
                tooltip={tooltip}
                ttmargin={ttmargin}
                onChange={(val) => {
                  handleChange(fieldName, val);
                  ipt?.onChange?.(val);
                }}
                placeholder={placeholder}
                defaultSelected={state[fieldName] || defaultValue}
              />
            </div>
          );
        case 'tableClick':
          return (
            <div className="py-4">
              <TableClick
                key={fieldName}
                fieldName={fieldName}
                header={fieldText}
                columns={
                  fieldName == 'source'
                    ? options.sort((a, b) => (b.type > a.type ? 1 : -1))
                    : ipt.options
                }
                items={ipt.options}
                onChange={(val) => {
                  handleChange(fieldName, val);
                  ipt?.onChange?.(val);
                }}
                columnHeaders={columnHeaders}
                placeholder={placeholder}
                defaultSelected={fieldName == 'source' ? state : state[fieldName] || defaultValue}
              />
            </div>
          );
        //Added for new names and backwards compatibility
        case 'twoRowGroup':
          return (
            <div className="py-4">
              <TwoRowGroup
                key={fieldName}
                header={fieldText}
                items={options}
                withtooltip={withtooltip}
                tooltip={tooltip}
                ttmargin={ttmargin}
                btnOn={btnOn}
                leftHeader={leftHeader}
                rightHeader={rightHeader}
                leftOptions={options}
                rightOptions={rightOptions}
                buttonText={ipt.buttonText}
                onChange={(val) => {
                  handleChange(fieldName, val);
                  ipt?.onChange?.(val);
                }}
                placeholder={placeholder}
                defaultSelected={state[fieldName] || structuredClone(defaultValue)}
                leftKeyName={leftKeyName}
                rightKeyName={rightKeyName}
              />
            </div>
          );
        case 'multiSelectSearch':
          var avaiOptions = options.map((option) => option.value);
          var fieldInTool = state[fieldName + '_order']?.map((item) => item.id) || [];
          var newFields = options.filter((option) => !fieldInTool.includes(option.value)) || [];
          var fields =
            state[fieldName + '_order']?.map((item) => {
              if (avaiOptions.includes(item.id)) {
                return item;
              } else {
                return { ...item, ...{ missing: true } };
              }
            }) || [];

          var data = [
            ...newFields.map((option) => {
              return {
                column: option.label,
                id: option.value,
                new: true,
              };
            }),
            ...fields,
          ];

          return (
            <div className="py-4">
              <TableSelect
                key={fieldName}
                header={fieldText}
                items={options}
                onChangeHandler={(initialRows, selectedRowIds) => {
                  // console.log("updateOrderChangeinitialRows", initialRows)
                  const newState = Object.assign({}, state);
                  const selectedFields = {};
                  initialRows
                    .filter((row) => selectedRowIds[row.original.column])
                    .forEach((key) => (selectedFields[key.id] = true));
                  newState[fieldName] = selectedFields;
                  newState[fieldName + '_order'] = initialRows
                    .filter(
                      (item) =>
                        !item.original.missing ||
                        Object.keys(selectedRowIds).includes(item.original.id)
                    )
                    .map((item) => {
                      return { column: item.original.column, id: item.original.id };
                    });
                  setState(newState);
                  stateChangeCallback(newState);

                  ipt?.onChange?.(selectedRowIds);
                }}
                // updateOrderChange={(val) => {
                // }}
                columns={[
                  {
                    Header: 'Column',
                    accessor: 'column',
                  },
                ]}
                data={
                  fields.length > 0
                    ? data
                    : options.map((option) => {
                      return {
                        column: option.label,
                        id: option.value,
                      };
                    })
                }
                placeholder={placeholder}
                defaultSelected={state[fieldName] || []}
                withtooltip={withtooltip}
                tooltip={tooltip}
                ttmargin={ttmargin}
              />
            </div>
          );
        case 'multiSelectSearch2':
          //Available options for incoming data
          var avaiOptions2 = options.map((option) => option.value);
          //console.log('avaiOptions2', avaiOptions2)

          //Current fields configured in tool
          var fieldsInTool2 = state[fieldName]?.map((item) => item.id) || [];
          //console.log('fieldsInTool2', fieldsInTool2)

          //Fields not already in the existing config
          var newFields2 = options.filter((option) => !fieldsInTool2.includes(option.value)) || [];
          //console.log('newFields2', newFields2)

          //Mark missing fields as missing if not available
          var fields2 =
            state[fieldName]?.map((item) => {
              if (avaiOptions2.includes(item.id)) {
                return { ...item, ...{ new: false } };
              } else {
                return { ...item, ...{ missing: true } };
              }
            }) || [];

          //Combine existing field with new fields
          var data2 = [
            ...newFields2.map((option) => {
              return {
                column: option.label,
                id: option.value,
                new: true,
                originalFormat: option.type,
                select: false,
                name: null,
              };
            }),
            ...fields2.map((item) => {
              return item;
            }),
          ];

          return (
            <div className="py-4">
              <TableSelect2
                key={fieldName}
                header={fieldText}
                // items={options}
                onChangeHandler={(newState) => {
                  handleChange(fieldName, newState);
                  ipt?.onChange?.(newState);
                }}
                columns={[
                  {
                    Header: 'Column',
                    accessor: 'column',
                  },
                  {
                    Header: 'Rename Column',
                    accessor: 'column2',
                  },
                  {
                    Header: 'Change Format',
                    accessor: 'column3',
                  },
                ]}
                //Data is either options or data2 depending on whether the tool has been configured before
                data={
                  fields2.length > 0
                    ? data2
                    : options.map((option) => {
                      return {
                        column: option.label,
                        id: option.value,
                        new: false,
                        select: false,
                        //select: true,
                        name: null,
                        //name: "Dummy",
                        missing: false,
                        originalFormat: option.type,
                        //format: "string",
                      };
                    })
                }
                placeholder={placeholder}
                withtooltip={withtooltip}
                tooltip={tooltip}
                ttmargin={ttmargin}
                formatItems={[
                  { label: 'None', value: null },
                  { label: 'Text', value: 'Utf8' },
                  { label: 'Whole Number', value: 'Int64' },
                  { label: 'Decimal', value: 'Float64' },
                  { label: 'Boolean', value: 'Boolean' },
                  { label: 'Datetime', value: "Datetime(time_unit='ns', time_zone=None)" },
                  { label: 'Date', value: 'Date' },
                ]}
              />
            </div>
          );
        case 'conditional':
          //console.log('This is the value', state[fieldName]);
          return (
            <div className="py-4">
              <Conditional
                key={fieldName}
                header={fieldText}
                withtooltip={withtooltip}
                tooltip={tooltip}
                ttmargin={ttmargin}
                options={options}
                buttonText={ipt.buttonText}
                onChange={(val) => {
                  handleChange(fieldName, val);
                  ipt?.onChange?.(val);
                }}
                placeholder={placeholder}
                defaultSelected={structuredClone(state[fieldName]) || structuredClone(defaultValue)}
              />
            </div>
          );
        case 'modellingFilter':
          return (
            <div className="py-4">
              <ModellingFilter
                key={fieldName}
                header={fieldText}
                withtooltip={withtooltip}
                tooltip={tooltip}
                ttmargin={ttmargin}
                options={options}
                rightOptions={ipt.rightOptions}
                buttonText={ipt.buttonText}
                onChange={(val) => {
                  handleChange(fieldName, val);
                  ipt?.onChange?.(val);
                }}
                placeholder={placeholder}
                defaultSelected={state[fieldName] || structuredClone(defaultValue)}
              />
            </div>
          );
        case 'text':
          return (
            <div className="py-4">
              <TextInputModelling
                key={fieldName}
                header={fieldText}
                subheader={description}
                name={fieldName}
                placeholder={placeholder}
                withtooltip={withtooltip}
                tooltip={tooltip}
                ttmargin={ttmargin}
                type="text"
                value={state[fieldName] || defaultValue}
                onChange={(val) => {
                  handleChange(fieldName, val);
                  ipt?.onChange?.(val);
                }}
              />
            </div>
          );
        case 'textarea':
          return (
            <div className="py-4">
              <TextAreaModelling
                key={fieldName}
                header={fieldText}
                subheader={description}
                name={fieldName}
                placeholder={placeholder}
                withtooltip={withtooltip}
                tooltip={tooltip}
                optional={optional}
                ttmargin={ttmargin}
                type="text"
                value={state[fieldName] || defaultValue}
                onChange={(val) => {
                  handleChange(fieldName, val);
                  ipt?.onChange?.(val);
                }}
              />
            </div>
          );
        case 'multidropdown':
          return (
            <div className="py-4">
              <MSelect
                key={fieldName}
                header={fieldText}
                items={options}
                onChange={(val) => {
                  handleChange(fieldName, val);
                  ipt?.onChange?.(val);
                }}
                placeholder={placeholder}
                defaultSelected={state[fieldName] || defaultValue}
                withtooltip={withtooltip}
                tooltip={tooltip}
                ttmargin={ttmargin}
                optional={optional}
              />
            </div>
          );
        case 'optionsTable':
          return (
            <div className="py-4">
              <GridRadioGroup
                key={fieldName}
                header={fieldText}
                columns={options}
                items={ipt.types}
                SecondColumnHeader={secondHeader}
                onChange={(val) => {
                  handleChange(fieldName, val);
                  ipt?.onChange?.(val);
                }}
                placeholder={placeholder}
                defaultSelected={state[fieldName]}
                defaultValue={defaultValue}
              />
            </div>
          );
        case 'textbox':
          return (
            <div className="py-4">
              <TextDescInput
                key={fieldName}
                heading={fieldText}
                subText={description}
                name={fieldName}
                value={state[fieldName] || defaultValue}
                placeholder={placeholder}
                onChange={(val) => {
                  handleChange(fieldName, val);
                  ipt?.onChange?.(val);
                }}
              />
            </div>
          );
        case 'password':
          return (
            <div className="py-4">
              <PasswordInput
                key={fieldName}
                header={fieldText}
                subheader={description}
                name={fieldName}
                value={state[fieldName] || defaultValue}
                onChange={(val) => {
                  handleChange(fieldName, val);
                  ipt?.onChange?.(val);
                }}
              />
            </div>
          );
        case 'RenameColumns':
          return (
            <div className="py-4">
              <GridTextInput
                key={fieldName}
                header={fieldText}
                columns={options}
                items={ipt.types}
                onChange={(val) => {
                  handleChange(fieldName, val);
                  ipt?.onChange?.(val);
                }}
                placeholder={placeholder}
                defaultSelected={state[fieldName] || defaultValue}
              />
            </div>
          );
        case 'KeyValueField':
          return (
            <div className="py-4">
              <KeyValueField
                key={fieldName}
                header={fieldText}
                items={options}
                withtooltip={withtooltip}
                tooltip={tooltip}
                optional={optional}
                ttmargin={ttmargin}
                classes="mr-5"
                leftOptions={options}
                rightOptions={ipt.rightOptions}
                buttonText={ipt.buttonText}
                onChange={(val) => {
                  handleChange(fieldName, val);
                  ipt?.onChange?.(val);
                }}
                placeholder={placeholder}
                defaultSelected={state[fieldName] || structuredClone(defaultValue)}
              />
            </div>
          );
        case 'description':
          return (
            <div className="py-4">
              <Description key={fieldName} header={fieldText} />
            </div>
          );
        case 'formula':
          return (
            // <div className="py-4">
            //   <Formulabox
            //     key={fieldName}
            //     header={fieldText}
            //     withtooltip={true}
            //     tooltip={
            //       <p>
            //         Use <span className="text-white dark:text-black font-bold">[</span> to bring up
            //         your columns (square bracket)
            //       </p>
            //     }
            //     name={fieldName}
            //     placerholder={placeholder}
            //     value={state[fieldName] || defaultValue}
            //     options={options.map(({ value }) => value)}
            //     onChange={(val) => {
            //       handleChange(fieldName, val);
            //       ipt?.onChange?.(val);
            //     }}
            //   />
            // </div>
            <div className="py-4">
              <ComboboxInput
                key={fieldName}
                header={fieldText}
                withtooltip={true}
                tooltip={tooltip}
                name={fieldName}
                optional={optional}
                columnCombo={columnCombo}
                placerholder={placeholder}
                defaultValue={state[fieldName] || defaultValue}
                options={options}
                onChange={(val) => {
                  handleChange(fieldName, val);
                  ipt?.onChange?.(val);
                }}
              />
            </div>
          );
        case 'combobox':
          return (
            <div className="py-4">
              <ComboboxInput
                key={fieldName}
                header={fieldText}
                withtooltip={true}
                tooltip={tooltip}
                name={fieldName}
                optional={optional}
                placerholder={placeholder}
                defaultValue={state[fieldName] || defaultValue}
                options={options}
                onChange={(val) => {
                  handleChange(fieldName, val);
                  ipt?.onChange?.(val);
                }}
                columnCombo={columnCombo}
              />
            </div>
          );
        case 'checkbox':
          return (
            <div className="py-4">
              <CheckboxText
                key={fieldName}
                fieldName={fieldName}
                header={fieldText}
                withtooltip={true}
                optional={optional}
                tooltip={tooltip}
                options={options}
                defaultValue={state[fieldName] || structuredClone(defaultValue)}
                onChangeCallback={(val) => {
                  handleChange(fieldName, val);
                  ipt?.onChange?.(val);
                }}
              />
            </div>
          );
        case 'radiotext':
          return (
            <div className="py-4">
              <RadioText
                key={fieldName}
                fieldName={fieldName}
                header={fieldText}
                //withtooltip={true}
                optional={optional}
                tooltip={tooltip}
                options={options}
                defaultValue={state[fieldName] || structuredClone(defaultValue)}
                onChangeCallback={(val) => {
                  handleChange(fieldName, val);
                  ipt?.onChange?.(val);
                }}
              />
            </div>
          );
        case 'radio':
          return (
            <div className="py-4 overflow-scroll">
              <RadioGroupModelling
                key={fieldName}
                header={fieldText}
                subheader={description}
                name={fieldName}
                items={options}
                withtooltip={withtooltip}
                tooltip={tooltip}
                ttmargin={ttmargin}
                value={state[fieldName] || defaultValue}
                onChange={(val) => {
                  handleChange(fieldName, val);
                  ipt?.onChange?.(val);
                }}
              />
            </div>
          );
        case 'checkboxSelect':
          return (
            <div className="py-4 overflow-scroll">
              <Checkbox
                key={fieldName}
                header={fieldText}
                name={fieldName}
                items={options}
                defaultValue={state[fieldName] || defaultValue}
                onChangeCallback={(val) => {
                  console.log('val', val);
                  handleChange(fieldName, val);
                  ipt?.onChange?.(val);
                }} />
            </div>
          );

        default:
          break;
      }
    });
    return fields;
  };

  const fields = generateFields(inputs.filter((input) => input?.advanced != true));
  const advanced = generateFields(inputs.filter((input) => input?.advanced == true));

  return (
    <div>
      <div className="divide-y divide-gray-200">
        {fields}
        {advanced.length > 0 && (
          <ModelconfigAccordion
            header={
              <span className="flex flex-row">
                Addtional Settings{' '}
                <p className="ml-1 text-zinc-500/80 dark:text-zinc-200/50">[Optional]</p>
              </span>
            }
          >
            {advanced}
          </ModelconfigAccordion>
        )}
      </div>
    </div>
  );
};

FormBuilderModelling.propTypes = {
  inputs: PropTypes.array,
  databases: PropTypes.array,
  tables: PropTypes.array,
  activeNode: PropTypes.object,
  submitHandler: PropTypes.func,
  withtooltip: PropTypes.bool,
  tooltip: PropTypes.string,
  ttmargin: PropTypes.string,

  // cancelHandler: PropTypes.func,
  deleteHandler: PropTypes.func,
  stateChangeCallback: PropTypes.func,
  token: PropTypes.string,
  submitButtonLabel: PropTypes.string,
  cancelButtonLabel: PropTypes.string,
  deleteButtonLabel: PropTypes.string,
  initState: PropTypes.object,
  mode: PropTypes.string,
  actionDelay: PropTypes.number,
};

FormBuilderModelling.defaultProps = {
  inputs: [],
  tables: [],
  databases: [],
  activeNode: {},
  submitHandler: () => null,
  // cancelHandler: () => null,
  deleteHandler: () => null,
  stateChangeCallback: () => null,
  submitButtonLabel: 'Done',
  cancelButtonLabel: 'Cancel',
  deleteButtonLabel: 'Delete',
  initState: {
    frequency: 0,
    days: '',
    time: '',
  },
  mode: 'edit',
  actionDelay: 500,
};

export default FormBuilderModelling;
