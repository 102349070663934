import React from 'react';
import PropTypes from 'prop-types';
import { DownloadIcon } from '@heroicons/react/solid';
// components
import Table from 'components/Tables/Table';
import TableRow from 'components/Tables/TableRow';
//import OnlyIconBtn from 'components/Buttons/OnlyIconBtn';
import ModalHeader from 'components/Layout/ModalHeader';
//import RadixTooltip from 'components/Tooltips/RadixTooltip';
import TemplatePopover from 'components/Buttons/TemplatePopover';

const ExportGrid = ({ object, tables, downloadData }) => {
  const headers = [
    { headerName: 'Table Name', classes: 'pl-3' },
    { headerName: '', classes: '' },
  ];

  console.log(object, downloadData);

  const exportActiveData = (tablename, format) => {
    downloadData(object, object.type, tablename, format);
  }
  const tableBody = tables.map((table) => (
    <tr key={table.table_name}>
      <TableRow classes="pl-3 py-1" content={table.table_name} />

      <td>
        <div className="justify-end flex flex-row flex-1 mt-1 mr-2 items-center whitespace-nowrap">
          <TemplatePopover
            btnText={
              <button
                className={`mt-[5px] group z-1 text-zinc-700 dark:text-zinc-100 items-center justify-center hover:bg-zinc-200 dark:hover:bg-zinc-700 rounded-md transition-all duration-150`}
              >
                <div className="p-[8px]">
                  <DownloadIcon className="h-4 w-4" />
                </div>
              </button>
            }
          >
            <div>
              <span className="border border-zinc-400/80 absolute p-[1px] h-full w-full rounded-lg bg-gradient-to-tr bg-zinc-100/50 dark:bg-zinc-800/80 backdrop-blur-sm"></span>
              <span className="relative rounded-lg bg-black dark:bg-zinc-200 opacity-90 dark:opacity-0 w-fit max-w-[220px]">
                <div className="max-w-fit font-medium text-xs text-left text-zinc-800 dark:text-red-100 break-words p-2 whitespace-normal flex flex-col gap-2">
                  <button onClick={() => exportActiveData(table.table_name, 'csv')} className="hover:underline">
                    Download as .csv
                  </button>
                  <button className="hover:underline" onClick={() => exportActiveData(table.table_name, 'xlsx')}>Download as .xlsx</button>
                </div>
              </span>
            </div>
          </TemplatePopover>
          {/* <RadixTooltip tooltipText="Download .csv file">
            <OnlyIconBtn
              onClick={() => {
                downloadData(object, object.type, table.table_name);
              }}
              icon={<DownloadIcon className="h-5 w-5" aria-hidden="true" />}
            />
          </RadixTooltip>
          <RadixTooltip tooltipText="Download .xlsx file">
            <OnlyIconBtn
              onClick={() => {
                downloadData(object, object.type, table.table_name);
              }}
              icon={<DownloadIcon className="h-5 w-5" aria-hidden="true" />}
            />
          </RadixTooltip> */}
        </div>
      </td >
    </tr >
  ));

  return (
    <div className="p-8 ">
      <ModalHeader
        header="Download data"
        content={
          <p className="mt-2  max-w-4xl text-sm align-baseline text-gray-500">
            You can download raw data to work with on your own computer. Helpful if you are or have
            some Excel-warriors in your team!
          </p>
        }
      />
      <div className="mt-2 ">
        <Table {...{ headers, tableBody }} />
      </div>
    </div>
  );
};

ExportGrid.propTypes = {
  object: PropTypes.object,
  type: PropTypes.string,
  tables: PropTypes.array,
  downloadData: PropTypes.func,
};

ExportGrid.defaultProps = {
  tables: [],
  downloadData: () => null,
};

export default ExportGrid;
