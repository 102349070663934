import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

// import BarAction from 'components/Actions/BarAction';
import actionArray from 'data/tools';
import ExampleHoverArrowMenu from 'components/Tooltips/NewTooltip';
import BarAction from 'components/Actions/BarAction';
import SmallBtnDropdown from 'components/Dropdowns/SmallBtnDropdown';
import OnlyIconBtn from 'components/Buttons/OnlyIconBtn';
import RadixTooltip from 'components/Tooltips/RadixTooltip';
import { ArrowLeftIcon, DownloadIcon, PlayIcon } from '@heroicons/react/solid';
import { FaUndo, FaRedo, FaStickyNote } from 'react-icons/fa';
import { MdGrid4X4 } from 'react-icons/md';
import TemplatePopover from 'components/Buttons/TemplatePopover';

const uniqueTags = [];

actionArray.map((item) => {
  if (uniqueTags.indexOf(item.category) === -1) {
    uniqueTags.push(item.category);
  }
});

const ActionBar = ({
  saveHandler,
  saveAsHandler,
  exportDatamodel,
  importModel,
  clearCacheHandler,
  restartSessionHandler,
  runFullModelHandler,
  model,
  annotationHandler,
  snapToGridHandler,
  snapToGrid,
  annotations,
  redo,
  undo,
  exportActiveData,
  goBack,
  isModellingUser,
}) => {
  const [selectedTag, setSelectedTag] = useState();
  const [filteredActions, setFilteredActions] = useState(actionArray);
  const [activeItem, setActiveItem] = useState('Basics');

  const ConnectionFilter = () => {
    let filtered = selectedTag
      ? actionArray.filter((c) => c.category === selectedTag)
      : actionArray.filter((c) => c.category === 'Basics');

    setFilteredActions(filtered);
  };

  const ActionAsc = [...filteredActions].sort((a, b) => (a.name > b.name ? 1 : -1));

  const displayScroll = localStorage.getItem('displayScroll') == "True" ? true : false
  const scrollProps = displayScroll ? "py-4" : "no-scrollbar py-2"

  const first = [];
  const rest = [];
  for (let i = 0; i < ActionAsc.length; i++) {
    if (ActionAsc[i].text === 'Input' || ActionAsc[i].text === 'Output') {
      first.push(ActionAsc[i]);
    } else {
      rest.push(ActionAsc[i]);
    }
  }
  const sorted = first.concat(rest);

  useEffect(ConnectionFilter, [selectedTag]);
  useEffect(ConnectionFilter, [actionArray]);

  const handler = (name) => {
    // filters
    name === selectedTag ? setSelectedTag('') : setSelectedTag(name);
    setActiveItem((currentItem) => (currentItem !== name ? name : -1));
  };

  const inputFile = useRef(null);

  const onButtonClick = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };

  const handleFileChange = (event) => {
    const fileUploaded = event.target.files[0];
    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      importModel(fileReader.result);
    };
    fileReader.readAsText(fileUploaded);
  };

  const createBtnItems = [
    {
      header: 'Save',
      action: (e) => {
        saveHandler(e);
      },
    },
    {
      header: 'Save as new model',
      action: (e) => {
        saveAsHandler(e);
      },
    },
    {
      header: 'Export datamodel',
      action: () => {
        exportDatamodel();
      },
    },
    {
      header: 'Import datamodel',
      action: () => {
        onButtonClick();
      },
    },
  ];

  return (
    <div className="">
      <input
        type="file"
        id="file"
        ref={inputFile}
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
      <div className="grid grid-cols-5 h-10 divide-x divide-zinc-300 dark:divide-zinc-600 my-1 place-content-center">
        <div className="col-span-2 gap-1 flex items-center">
          {uniqueTags.map((cat) => (
            <span
              className={`${activeItem === cat
                ? 'text-zinc-700 dark:text-zinc-50 bg-zinc-200/75 dark:bg-zinc-700 border-zinc-300 dark:border-zinc-500'
                : 'text-zinc-400 dark:text-zinc-400 bg-none hover:bg-zinc-200/25 dark:hover:bg-zinc-700/25'
                } flex h-6 flex-row items-end gap-1 font-medium text-xs py-1 px-2 rounded border-[0.5px] transition-all duration-200 border-transparent hover:border-zinc-300 dark:hover:border-zinc-500 hover:cursor-pointer`}
              key={cat}
              onClick={() => handler(cat)}
            >
              {cat}
            </span>
          ))}
        </div>
        <div className="text-xs items-center text-center font-medium tracking-wide text-zinc-700 dark:text-zinc-50 justify-center inline-flex">
          {model}
        </div>
        <div className="inline-flex gap-3 items-center justify-between col-span-2 pl-5">
          <div className="flex flex-row items-center justify-center gap-2.5">
            {isModellingUser && (
              <>
                <TemplatePopover
                  btnText={
                    <button
                      className={`mt-[5px] border dark:border-zinc-700 group z-1 text-zinc-700 dark:text-zinc-100 items-center justify-center hover:bg-zinc-200 dark:hover:bg-zinc-700 rounded-md transition-all duration-150`}
                    >
                      <div className="p-[8px]">
                        <DownloadIcon className="h-3.5 w-3.5" />
                      </div>
                    </button>
                  }
                >
                  <div>
                    <span className="border border-zinc-400/80 absolute p-[1px] h-full w-full rounded-lg bg-gradient-to-tr bg-zinc-100/50 dark:bg-zinc-800/80 backdrop-blur-sm"></span>
                    <span className="relative rounded-lg bg-black dark:bg-zinc-200 opacity-90 dark:opacity-0 w-fit max-w-[220px]">
                      <div className="max-w-fit font-medium text-xs text-left text-zinc-800 dark:text-red-100 break-words p-2 whitespace-normal flex flex-col gap-2">
                        <button onClick={() => exportActiveData('csv')} className="hover:underline">
                          Download as .csv
                        </button>
                        <button className="hover:underline" onClick={() => exportActiveData('xlsx')}>Download as .xlsx</button>
                      </div>
                    </span>
                  </div>
                </TemplatePopover>
                {/* <RadixTooltip childClasses="-mb-[5px]" tooltipText={'Download data'}>
                  <OnlyIconBtn
                    onClick={exportActiveData}
                    icon={<DownloadIcon className="h-3.5 w-3.5" />}
                    loader={true}
                    classes={'border dark:border-zinc-700'}
                  />
                </RadixTooltip> */}
                <RadixTooltip childClasses="-mb-[5px]" tooltipText={'Undo'}>
                  <OnlyIconBtn
                    onClick={undo}
                    icon={<FaUndo className=" h-3.5 w-3.5 scale-75" />}
                    classes={'border dark:border-zinc-700'}
                  />
                </RadixTooltip>
                <RadixTooltip childClasses="-mb-[5px]" tooltipText={'Redo'}>
                  <OnlyIconBtn
                    onClick={redo}
                    icon={<FaRedo className="h-3.5 w-3.5 scale-75" />}
                    classes={'border dark:border-zinc-700'}
                  />
                </RadixTooltip>
              </>
            )}
            <RadixTooltip childClasses="-mb-[5px]" tooltipText={'Toogle Annotations'}>
              <OnlyIconBtn
                onClick={annotationHandler}
                icon={<FaStickyNote className="h-3.5 w-3.5 scale-75" />}
                classes={'border dark:border-zinc-700'}
                permanent={annotations}
              />
            </RadixTooltip>
            {isModellingUser && (
              <>
                <RadixTooltip childClasses="-mb-[5px]" tooltipText={'Snap to grid'}>
                  <OnlyIconBtn
                    onClick={snapToGridHandler}
                    icon={<MdGrid4X4 className="h-3.5 w-3.5 scale-75" />}
                    classes={'border dark:border-zinc-700'}
                    permanent={snapToGrid}
                  />
                </RadixTooltip>
                <RadixTooltip childClasses="-mb-[5px]" tooltipText={'Clear caching'}>
                  <OnlyIconBtn
                    onClick={() => {
                      return clearCacheHandler();
                    }}
                    icon={
                      <svg
                        className="w-3.5 h-3.5 p-px"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M1.5 1.5L22.5 22.5" stroke="currentColor" strokeWidth="2" />
                        <path d="M22.5 1.5L1.5 22.5" stroke="currentColor" strokeWidth="2" />
                      </svg>
                    }
                    loader={true}
                    classes={'border dark:border-zinc-700'}
                  />
                </RadixTooltip>
                <RadixTooltip childClasses="-mb-[5px]" tooltipText={'Restart session'}>
                  <OnlyIconBtn
                    onClick={() => {
                      return restartSessionHandler();
                    }}
                    icon={
                      <svg
                        className="w-3.5 h-3.5"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M10 22L6 18L10 14" stroke="currentColor" strokeWidth="2" />
                        <path d="M6 18H22V11" stroke="currentColor" strokeWidth="2" />
                        <path d="M14 2L18 6L14 10" stroke="currentColor" strokeWidth="2" />
                        <path d="M18 6H2V13" stroke="currentColor" strokeWidth="2" />
                      </svg>
                    }
                    loader={true}
                    classes={'border dark:border-zinc-700'}
                  />
                </RadixTooltip>
                <RadixTooltip childClasses="-mb-[5px]" tooltipText={'Run all Actions'}>
                  <OnlyIconBtn
                    onClick={() => {
                      return runFullModelHandler();
                    }}
                    icon={<PlayIcon className="h-3.5 w-3.5" />}
                    loader={true}
                    classes={'border dark:border-zinc-700'}
                  />
                </RadixTooltip>
              </>
            )}
          </div>
          <div className="inline-flex gap-3">
            <button
              onClick={goBack}
              className="group flex px-2.5 py-1 rounded-[10px] font-normal items-center duration-150 hover:shadow justify-center bg-white dark:bg-transparent text-xs text-zinc-500 dark:text-zinc-200 border-zinc-300 dark:border-zinc-500 border-2  hover:bg-black hover:border-black dark:hover:bg-zinc-50 dark:hover:border-zinc-50 focus:ring-0 focus:ring-transparent hover:text-zinc-50 dark:hover:text-zinc-700"
            >
              <div className="mr-1 group-hover:text-white dark:group-hover:text-black dark:text-zinc-50">
                <ArrowLeftIcon
                  className={`-ml-1 mr-0.5 h-3.5 w-3.5 duration-300 transition-all dark:group-hover:text-black `}
                />
              </div>
              <p className="text-[12px]">Exit</p>
            </button>
            {isModellingUser && <SmallBtnDropdown btnHeader="Save" items={createBtnItems} />}
          </div>
        </div>
      </div>

      <ul
        className={`${selectedTag === 'Save'
          ? 'flex flex-row gap-1 justify-start'
          : 'grid grid-flow-col auto-cols-max gap-2'
          } w-full   border-t overflow-x-scroll ${scrollProps} border-zinc-300 dark:border-zinc-500 bg-transparent `}
      >
        <div className="hidden bg-zinc-800 text-white dark:border-zinc-50" />
        <div className="hidden bg-[#e2e8f0]  text-slate-800 border-slate-600/20" />
        <div className="hidden bg-[#ffe8e3]  text-[#664f4a] border-[#ffc6ba]" />
        <div className="hidden bg-[#bedbfe] text-[#254265] border-[#5da6fc]/75" />
        <div className="hidden bg-[#ffbbc9] text-[#662230] border-[#ff5478]/75" />
        {sorted.map((action) => (
          <>
            {action.vidLink && (
              <ExampleHoverArrowMenu
                key={action.name}
                reference={
                  <BarAction
                    key={action.name}
                    tool={action}
                    icon={action.icon}
                    classes={
                      action.text === 'Input' || action.text === 'Output'
                        ? 'bg-zinc-800 text-white dark:border-zinc-50'
                        : action.category === 'Basics'
                          ? 'bg-[#e2e8f0]  text-slate-800 border-slate-600/20 dark:border-slate-600'
                          : action.category === 'New Columns'
                            ? 'bg-[#ffe8e3]  text-[#664f4a] border-[#ffc6ba] '
                            : action.category === 'Reshape'
                              ? 'bg-[#bedbfe] text-[#254265] border-[#5da6fc]/75'
                              : action.category === 'Merge'
                                ? 'bg-[#ffbbc9] text-[#662230] border-[#ff5478]/75'
                                : 'bg-white text-zinc-700 border-zinc-600/20'
                    }
                    name={action.text}
                  />
                }
                tooltipText={action.tooltip}
                animaPath={action.vidLink}
              />
            )}
            {!action.vidLink && (
              <BarAction
                key={action.name}
                tool={action}
                icon={action.icon}
                classes={
                  action.text === 'Input' || action.text === 'Output'
                    ? 'bg-zinc-800 text-white'
                    : action.category === 'Basics'
                      ? 'bg-[#e2e8f0]  text-slate-800 border-slate-600/20'
                      : action.category === 'New Columns'
                        ? 'bg-[#ffe8e3]  text-[#664f4a] border-[#ffc6ba]'
                        : action.category === 'Reshape'
                          ? 'bg-[#bedbfe] text-[#254265] border-[#5da6fc]/75'
                          : action.category === 'Merge'
                            ? 'bg-[#ffbbc9] text-[#662230] border-[#ff5478]/75'
                            : 'bg-white text-zinc-700 border-zinc-600/20'
                }
                name={action.text}
              />
            )}
          </>
        ))}
      </ul>
    </div>
  );
};

ActionBar.propTypes = {
  saveHandler: PropTypes.func,
  saveAsHandler: PropTypes.func,
  exportDatamodel: PropTypes.func,
  importModel: PropTypes.func,
  clearCacheHandler: PropTypes.func,
  restartSessionHandler: PropTypes.func,
  runFullModelHandler: PropTypes.func,
  model: PropTypes.string,
  redo: PropTypes.func,
  undo: PropTypes.func,
  goBack: PropTypes.func,
  exportActiveData: PropTypes.func,
  annotationHandler: PropTypes.func,
  snapToGridHandler: PropTypes.func,
  snapToGrid: PropTypes.func,
  annotations: PropTypes.func,
  isModellingUser: PropTypes.bool,
};

export default React.memo(ActionBar);
